import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Collapse,
  Input,
  Table,
  InputNumber,
  Pagination,
} from "antd";
import { useQuery } from "@tanstack/react-query";

import { connect } from "react-redux";
import { orderBy } from "lodash";
import { MinusOutlined } from "@ant-design/icons/es/icons";
import { PlusOutlined } from "@ant-design/icons";
import BannerTop from "../../components/BannerTop/BannerTop";
import Tree from "../../components/Tree/Tree";
import Label from "../../components/Label/Label";
import Localize, { l } from "../../libs/localization";
import { searchBrandAdvertisersApi } from "../../api/frontend.api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { getTreeDataV2Api } from "../../api/registry.api";

const { Panel } = Collapse;

const BrandAdvertisersSearch = () => {
  const [filters, setFilters] = useState({
    sector: [],
    companyIds: [],
    page: 0,
    pageSize: 20,
  });

  const [reachFilter, setReachFilter] = useState({});

  const advertisersQuery = useQuery({
    queryKey: ["brandAdvertisers", filters],
    queryFn: () => searchBrandAdvertisersApi({ filters }),
    placeholderData: (prev) => prev,
  });

  const sectorsQuery = useQuery({
    queryKey: ["sectors"],
    queryFn: () => getTreeDataV2Api({ entity: "Sector" }),
  });

  const data = [];
  if (advertisersQuery.data?.results)
    advertisersQuery.data.results.forEach((item) => {
      data.push({
        company: {
          name: item.brand.company?.name,
          id: item.brand.company?.id,
        },
        brand: {
          name: item.brand.name,
          id: item.brand._id,
          companyId: item.brand.company?.id,
        },
        year: item.year,
        payer: item.payer,
        beneficiary: item.beneficiary,
        ads: item.n_ads,
        reach: item.eu_total_reach,
        estimatedSpending: item.eu_total_reach * 2.5 * 0.007,
        libraryUrl: `https://www.facebook.com/ads/library/?view_all_page_id=${item.advertiser.metaLibraryId}`,
      });
    });
  const eur = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const columns = [
    {
      title: l("COMMON.COMPANY"),
      dataIndex: "company",
      key: "company",
      render: ({ id, name }) => <Link to={`/companies/${id}`}>{name}</Link>,
    },
    {
      title: l("COMMON.BRAND"),
      dataIndex: "brand",
      key: "brand",
      render: ({ id, name, companyId }) => (
        <Link to={`/companies/${companyId}?brand=${id}`}>{name}</Link>
      ),
    },
    {
      title: l("COMMON.YEAR"),
      dataIndex: "year",
      key: "year",
    },
    {
      title: l("PAGES.SEARCH.PAYER"),
      dataIndex: "payer",
      key: "payer",
    },
    {
      title: l("BRAND_ADVERTISERS.BENEFICIARY"),
      dataIndex: "beneficiary",
      key: "beneficiary",
    },
    {
      title: l("BRAND_ADVERTISERS.N_ADS"),
      dataIndex: "ads",
      key: "ads",
    },
    {
      title: l("PAGES.SEARCH.REACH"),
      dataIndex: "reach",
      key: "reach",
      render: (reach) => reach.toLocaleString(),
    },
    {
      title: l("BRAND_ADVERTISERS.ESTIMATED_SPENDING"),
      dataIndex: "estimatedSpending",
      key: "estimatedSpending",
      render: (estimatedSpending) => eur.format(estimatedSpending),
    },
    {
      title: l("BRAND_ADVERTISERS.ADL_URL"),
      dataIndex: "libraryUrl",
      key: "libraryUrl",
      render: (libraryUrl) => (
        <a href={libraryUrl} target="_blank" rel="noreferrer">
          {l("COMMON.OPEN")}
        </a>
      ),
    },
  ];

  const pagination = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 0",
      }}
    >
      <Pagination
        total={advertisersQuery.data?.pageInfo?.total}
        current={filters.page + 1}
        onChange={(page, pageSize) =>
          setFilters({ ...filters, page: page - 1, pageSize })
        }
        pageSize={filters.pageSize}
        pageSizeOptions={["20", "50", "100"]}
      />
    </div>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">
          {Localize("PAGES.BRAND_ADVERTISERS.TITLE")}
        </h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.COMPANIES")}
              />
              <CompanyAutocomplete
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    companyIds: value.map((e) => e?.id),
                    page: 0,
                  });
                }}
              />
            </Col>
            <Col span={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
              />
              <Tree
                translateEntity="Sector"
                valueData={filters.sector}
                datatree={orderBy(sectorsQuery?.data || [], ["key"], ["asc"])}
                onChange={(value) => {
                  setFilters({ ...filters, sector: value, page: 0 });
                }}
                onSelect={(value, e) => {
                  setFilters({ ...filters, sector: value, page: 0 });
                }}
              />
            </Col>
            <Col span={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.PAYER")}
              />
              <Input
                value={filters.payer}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    payer: e.target.value,
                    page: 0,
                  });
                }}
              />
            </Col>
          </Row>
          <Collapse ghost={true} style={{ marginLeft: "-15px" }}>
            <Panel key="1" header={l("PAGES.SEARCH.REACH")}>
              {filters.reach?.map((row, i) => {
                return (
                  <Row gutter={16}>
                    <Col span={6}>
                      {Localize("COMMON.YEAR")}: {row.year}
                    </Col>
                    <Col span={6}>
                      {row.minReach
                        ? `${l("PAGES.SEARCH.REACH_MINIMUM")}: ${row.minReach}`
                        : ""}
                    </Col>

                    <Col span={6}>
                      {row.maxReach
                        ? `${l("PAGES.SEARCH.REACH_MAXIMUM")}: ${row.maxReach}`
                        : ""}
                    </Col>
                    <Col span={6}>
                      <Button
                        type="default"
                        onClick={() => {
                          setFilters({
                            ...filters,
                            reach: filters.reach?.filter((_, j) => j !== i),
                            page: 0,
                          });
                        }}
                      >
                        <MinusOutlined />
                        {l("COMMON.REMOVE")}
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <Row gutter={16}>
                <Col span={6}>
                  <Label className="filter-color" value={l("COMMON.YEAR")} />
                  <InputNumber
                    style={{ width: "100%" }}
                    min={2000}
                    max={new Date().getFullYear()}
                    value={reachFilter.year}
                    onChange={(value) =>
                      setReachFilter({ ...reachFilter, year: value })
                    }
                  />
                </Col>
                <Col span={6}>
                  <Label
                    className="filter-color"
                    value={Localize("PAGES.SEARCH.REACH_MINIMUM")}
                  />
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    value={reachFilter.minReach}
                    onChange={(value) =>
                      setReachFilter({ ...reachFilter, minReach: value })
                    }
                  />
                </Col>
                <Col span={6}>
                  <Label
                    className="filter-color"
                    value={Localize("PAGES.SEARCH.REACH_MAXIMUM")}
                  />
                  <InputNumber
                    style={{ width: "100%" }}
                    min={reachFilter.minReach || 0}
                    value={reachFilter.maxReach}
                    onChange={(value) =>
                      setReachFilter({ ...reachFilter, maxReach: value })
                    }
                  />
                </Col>
                <Col span={6}>
                  <Button
                    disabled={
                      !reachFilter.year ||
                      reachFilter.minReach > reachFilter.maxReach
                    }
                    type="primary"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        reach: [...(filters.reach || []), { ...reachFilter }],
                        page: 0,
                      });
                      setReachFilter({});
                    }}
                    style={{ marginTop: 36 }}
                  >
                    <PlusOutlined />
                    {Localize("COMMON.ADD")}
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Card>
      </div>
      <div>
        {pagination}
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={advertisersQuery.isFetching}
        />
        {pagination}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(BrandAdvertisersSearch);
